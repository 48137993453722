import React, { FC } from 'react';
import PropTypes, { any } from 'prop-types';

interface ILogoProps {
	width?: number;
	height?: number;
	darkMode?: any;
}
const Logo: FC<ILogoProps> = ({ width, height, darkMode }) => {
	// eslint-disable-next-line jsx-a11y/alt-text
	return (
		<img
			style={{ width: '180px' }}
			src={darkMode ? '/images/logo-macro-light.png' : '/images/logo-macro-dark.png'}
			alt='Logo'
		/>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
	darkMode: true,
};

export default Logo;
