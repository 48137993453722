import axios from 'axios';
import config from '@this/impl/config';
import { useQuery } from '@tanstack/react-query';
import * as fs from 'fs';
import { error } from 'console';

const basePath = config.apiHost;

export const getData =
	(
		endpoint: String,
		options: { onlyPublic?: boolean; defaultOn404?: any } = {
			onlyPublic: false,
			defaultOn404: undefined,
		},
	) =>
	() => {
		const headers: any = {};
		const { onlyPublic } = options;

		if (onlyPublic) {
			headers['x-public'] = true;
		}

		if (axios.defaults.headers.common.Authorization) {
			return (
				axios
					.get(`${basePath}${endpoint}`, {
						headers,
					})
					.then((response) => {
						return response.data;
					})
					// eslint-disable-next-line @typescript-eslint/no-shadow
					.catch((error) => {
						if (error.response?.status === 404) {
							return options.defaultOn404;
						}
						throw error;
					})
			);
		}
		return Promise.reject(new Error('No token stored'));
	};

export const postData = (endpoint: String, entity: any) => {
	return axios.post(`${basePath}${endpoint}`, entity);
};

export const postForm = async (endpoint: String, entity: any) =>
	axios.postForm(
		`${basePath}${endpoint}`,
		{ ...entity },
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		},
	);

export const patchForm = async (endpoint: String, entity: any) =>
	axios.patchForm(
		`${basePath}${endpoint}`,
		{ ...entity },
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		},
	);

export const patchData = async (endpoint: String, entity: any) => {
	const id = entity._id ?? entity.id;
	if (id) {
		return axios.patch(`${basePath}${endpoint}/${id}`, entity);
	}
	return axios.patch(`${basePath}${endpoint}`, entity);
};
