import React from 'react';
import Clock from './clock';
import Search from './search';

const SubHeader = ({}) => {
	return (
		<>
			{/* <Clock /> */}
			<Search />
		</>
	);
};
export default SubHeader;
