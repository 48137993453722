import React, { useContext, useLayoutEffect } from 'react';
import Popovers from '@this/template/components/bootstrap/Popovers';
import useDeviceScreen from '@this/template/hooks/useDeviceScreen';
import Header, { HeaderLeft, HeaderRight } from '@this/template/layout/Header/Header';
import Navigation from '@this/template/layout/Navigation/Navigation';
import { pageLayoutTypesPagesMenu } from '@this/template/menu';
import api from '@this/impl/api';
import * as _ from 'lodash';
import User from '@this/template/layout/User/User';
import useDarkMode from '@this/template/hooks/useDarkMode';
import Button, { IButtonProps } from '@this/template/components/bootstrap/Button';
import UserHeader from '@this/template/layout/User/UserHeader';
import AuthContext from '@this/template/contexts/authContext';
import { log } from 'console';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '@this/template/components/bootstrap/Dropdown';
import Spinner from '@this/template/components/bootstrap/Spinner';
import i18n from '@this/template/i18n';
import LANG, { getLangWithKey, ILang } from '@this/template/lang';
import { changeLanguage } from 'i18next';
import { useLocation } from 'react-router-dom';
import Search from './search';
import Canvas from '../documents/canvas';
import DocumentsCards from '../documents/cards';

const MainHeader = () => {
	const { width } = useDeviceScreen();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { userData, logout } = useContext(AuthContext);
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const { data, status, isFetching } = api.Public.useSectionsTree();

	const sectionsTree = data?.map((d) => api.Public.mapSectionsTreeMenu(d));

	const menu: { [k: string]: any } = {};

	_.forEach(sectionsTree, (r) => {
		menu[r.id] = r;
	});

	const { data: recentViewed, isFetching: recentViewedFetching } = api.Public.useDocuments({ recentViewed: true });
	const [recentViewedVisible, setRecentViewedVisible] = React.useState(false);
	const handleRecentViewedClose = () => setRecentViewedVisible(false);
	const location = useLocation();

	useLayoutEffect(() => {
		setRecentViewedVisible(false);
	}, [location.pathname]);
	
	return (
		<>
			<Canvas visible={recentViewedVisible} onClose={handleRecentViewedClose} title='Últimos documentos visitados'>
				<DocumentsCards data={recentViewed} cardsPerRow={1} />
			</Canvas>

			<Header>
				<HeaderLeft>
					<div className='row g-8 align-items-center'>
						<div className='col-auto col-md-hidden' style={{ fontSize: '15px', fontWeight: 'bold' }}>
							Intranet Normativa
						</div>
						<div className='col-auto col-md-8 '>
							<Search />
						</div>
					</div>
				</HeaderLeft>
				<HeaderRight>
					<div className='row g-3 align-items-center'>
						<div className='col-auto'>
							<Dropdown>
								<DropdownToggle hasIcon={false}>

									<Button
									
										color={darkModeStatus ? 'info' : 'info'}
										target='_blank'
										icon='Person'
										className='rounded-1 p-2 '
										size='sm'>
										Mi Intranet
									</Button>

								</DropdownToggle>
								<DropdownMenu isAlignmentEnd >
									<DropdownItem key='latest-docs'>
										<Button onClick={() => setRecentViewedVisible(true)}>
											Últimos documentos visitados
										</Button>
									</DropdownItem>

								</DropdownMenu>
							</Dropdown>
						</div>
						{/* Dark Mode */}
						<div className='col-auto'>
							<Popovers trigger='hover' desc='Modo Oscuro / Claro'>
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									icon={darkModeStatus ? 'LightMode' : 'DarkMode'}
									color={darkModeStatus ? 'info' : 'primary'}
									onClick={() => setDarkModeStatus(!darkModeStatus)}
									aria-label='Toggle fullscreen'
									data-tour='dark-mode'
									size='sm'
								/>
							</Popovers>
						</div>
						<div className='col-auto'>
							<Popovers trigger='hover' desc='Cerrar Sesión'>
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									icon='Logout'
									color={darkModeStatus ? 'info' : 'primary'}
									onClick={() => logout()}
									aria-label='Cerrar Sesion'
									data-tour='dark-mode'
									size='sm'
								/>
							</Popovers>
						</div>
						<div className='col-auto'>
							<UserHeader collapsible={false} />
						</div>


					</div>
				</HeaderRight>
			</Header>
		</>
	);
};

export default MainHeader;
