import React, { lazy } from 'react';

const ADMIN = {
	FOOTER: lazy(() => import('@this/impl/pages/admin/footer')),
};

const routes = [
	{
		path: '/admin/*',
		element: <ADMIN.FOOTER />,
	},
];

export default routes;
