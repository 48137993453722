import { useQuery } from '@tanstack/react-query';
import { getData, patchData, postData } from './base';
import Profile from './models/profile';
import User from './models/user';
import Group from './models/group';
import { useEntityQuery } from './entityQuery';
import ResourcePermission from './models/resource.permission';

const useProfiles = (id?: string) =>
	useEntityQuery<Profile>({
		name: 'profiles',
		path: '/membership/profiles',
		id,
		enabled: true,
		tree: false,
	});

const useUsers = (id?: string) =>
	useEntityQuery<User>({
		name: 'users',
		path: '/membership/users',
		id,
		enabled: true,
		tree: false,
	});

const useGroups = () =>
	useQuery<Group[], Error>(['groups'], getData('/membership/groups'), {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

const usePrivileges = () =>
	useQuery<string[], Error>(['privileges'], getData('/membership/privileges'), {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

const useResources = (id?: string) => {
	return {
		query: useEntityQuery<ResourcePermission>({
			name: 'resources',
			path: '/membership/resources',
			id,
			enabled: false,
			tree: false,
		}),
		me: useQuery<ResourcePermission, Error>(
			['resources', id, 'me'],
			getData(`/membership/resources/${id}/me`),
			{
				enabled: id !== undefined,
				refetchOnWindowFocus: false,
				refetchOnMount: false,
				cacheTime: 500,
			},
		),
	};
};

export default {
	useProfiles,
	useGroups,
	useUsers,
	usePrivileges,
	useResources,
};
