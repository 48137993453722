import React, { FC, HTMLAttributes, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import PlaceholderImage from '@this/template/components/extras/PlaceholderImage';

interface IProgressiveImageProps extends HTMLAttributes<HTMLOrSVGElement> {
	width?: string | number;
	height?: string | number;
	text?: string;
	className?: string;
	ariaLabel?: string;
	src: string;
	errorText?: string;
}

const ProgressiveImage: FC<IProgressiveImageProps> = ({
	src,
	width,
	height,
	text,
	errorText,
	className,
	ariaLabel,
}) => {
	const [error, setError] = useState(false);

	return error ? (
		<PlaceholderImage text={errorText} width='100%' height='100%' className='rounded' />
	) : (
		<img
			src={src ?? ''}
			alt={ariaLabel}
			className={className}
			onError={(c) => {
				c.currentTarget.onerror = null;
				setError(true);
			}}
		/>
	);
};

ProgressiveImage.propTypes = {
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	text: PropTypes.string,
	className: PropTypes.string,
	ariaLabel: PropTypes.string,
	errorText: PropTypes.string,
};
ProgressiveImage.defaultProps = {
	width: '100%',
	height: '100%',
	errorText: 'no disponible',
	text: undefined,
	className: undefined,
	ariaLabel: undefined,
};

export default ProgressiveImage;
