import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import PaginationButtons, {
	PER_COUNT,
	dataPagination,
} from '@this/template/components/PaginationButtons';
import Button from '@this/template/components/bootstrap/Button';
import Card, { CardBody } from '@this/template/components/bootstrap/Card';
import Icon from '@this/template/components/icon/Icon';
import useDarkMode from '@this/template/hooks/useDarkMode';
import useSortableData from '@this/template/hooks/useSortableData';
import classNames from 'classnames';
import LinesEllipsis from 'react-lines-ellipsis';
import Badge from '@this/template/components/bootstrap/Badge';
import Input from '@this/template/components/bootstrap/forms/Input';
import { FormikProps } from 'formik';
import dayjs from 'dayjs';
import _, { debounce } from 'lodash';
import { useDebounce, useDebounceValue, useLocalStorage } from 'usehooks-ts';
import Loading from '@this/template/components/loading';
import { EntityField } from './entity.field';
import ConfirmModal from './confirm.modal';

interface EntityTableProps<T> {
	data: T[] | undefined;
	fields?: EntityField[];
	loading: boolean;
	emptyMessage?: string;
	onEdit?: (e: T) => void;
	onSelect?: (e: T) => void;
	onSelectIcon?: string;
	onDelete?: (e: T) => void;
	onSwitchStatus?: (e: T) => void;
	pagingHidden?: boolean;
	editionMode?: boolean;
	formik?: FormikProps<T | {}>;
	nosortable?: boolean;
	showSearch?: boolean;
	searchText?: string;
	title?: string;
}

const EntityTable = <T extends unknown>({
	data,
	fields,
	loading,
	emptyMessage,
	onEdit,
	onSelect,
	onSelectIcon,
	onSwitchStatus,
	onDelete,
	pagingHidden,
	editionMode,
	formik,
	nosortable,
	showSearch,
	searchText,
	title,
}: EntityTableProps<T>) => {
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [currentPage, setCurrentPage] = useState(1);

	const [selectedElement, setSelectedElement] = useState<any>();

	const [perPage, setPerPage] = useState(PER_COUNT['50']);

	const [filterText, setFilterText] = useState('');
	const [filter, setFilter] = useDebounceValue(filterText, 500);
	const [filteredData, setFilteredData] = useState<T[]>(data ?? []);

	useEffect(() => {
		setFilteredData(data ?? []);
	}, [data]);

	const { items, requestSort, getClassNamesFor } = useSortableData(filteredData);

	const handleSelect = (entity: any) => onSelect && onSelect(entity);

	const [modalConfirmationVisible, setModalConfirmationVisible] = useState(false);

	const filterTable = (query: string) => {
		if (query && query !== '' && query.length >= 2) {
			const fieldNames: string[] = [];

			if (fields) {
				const a = fields?.filter((f) => f.filter && f.name).map((f) => f.name ?? '');
				const b = fields?.filter((f) => f.filter && f.subName).map((f) => f.subName ?? '');
				const c = fields
					?.filter((f) => f.filter && f.tagsName)
					.map((f) => f.tagsName ?? '');

				fieldNames.push(...a, ...b, ...c);

				const queries = query.toLowerCase().split(' ');

				setFilteredData(
					data?.filter((x) => {
						return (
							fieldNames?.some((f) => {
								const value = (x as any)[f];
								let valueA = [];
								if (Array.isArray(value)) {
									value.forEach((v) =>
										valueA.push(v?.toString().toLowerCase().trim().split(' ')),
									);
								} else {
									valueA = value?.toString().toLowerCase().trim().split(' ');
								}

								const s = valueA?.some((v: string) =>
									queries?.some(
										(q) =>
											v
												?.toString()
												.toLowerCase()
												.localeCompare(q, undefined, {
												sensitivity: 'base',
											}) === 0 || v?.toString().toLowerCase().includes(q),
									),
								);
								return s;
							}) ?? false
						);
					}) ?? [],
				);
			}
		} else {
			setFilteredData(data ?? []);
		}
	};

	// Debounce the filter
	useEffect(() => {
		filterTable(filter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	const handleFilterChange = (e: any) => {
		setFilter(e.target.value);
		setFilterText(e.target.value);
	};

	const handleSwitchStatusConfirm = () => {
		selectedElement && onSwitchStatus && onSwitchStatus(selectedElement);
		setModalConfirmationVisible(false);
	};

	const handleSwitchStatusClose = () => {
		setModalConfirmationVisible(false);
		setSelectedElement(undefined);
	};

	const handleSwitchStatus = (entity: T) => {
		setSelectedElement(entity);
		setModalConfirmationVisible(true);
	};

	const handleDelete = (entity: T) => onDelete && onDelete(entity);

	const handleChange = (e: any) => {};

	const editionField = (item: any, f: EntityField, onChange: any) => {
		const index = data?.indexOf(item);
		const value = item[f.name ?? ''];
		return f.editionElement ? (
			f.editionElement({ item, f, index })
		) : (
			<Input
				id={f.name}
				name={f.name}
				onChange={(e: any) => {
					onChange(e.target.value);
				}}
				value={value}
				// isValid={formik.errors.description === null}
				// isTouched={formik.touched.description}
				// invalidFeedback={formik.errors.description}
			/>
		);
	};

	const fieldDisplay = (item: any, f: EntityField) => {
		if (!f.name) return null;
		const fValue = f.name ? _.get(item, f.name) : '';
		const value = f.value ? f.value(item, f) : fValue;

		const isString = typeof value === 'string' && f.type !== 'date';
		const isDate = (typeof value === 'object' && value instanceof Date) || f.type === 'date';

		if (f.render) {
			return f.render(item, f);
		}
		if (!value) return undefined;
		return f.name && Array.isArray(value) ? (
			value.map((x: any, index) => (
				<span
					key={index}
					className={classNames(
						f.badge ? 'badge bg-secondary me-1' : '',
						f.selectable ? 'cursor-pointer' : '',
					)}>
					{' '}
					{x.name}
				</span>
			)) ?? ''
		) : (
			<span
				className={classNames(
					f.badge ? 'badge bg-secondary align-middle' : '',
					f.selectable ? 'cursor-pointer' : '',
				)}>
				{f.name && isString && value}
				{f.name && isDate && dayjs(`${value}`).format('DD/MM/YYYY')}
				{f.name && !isDate && !isString && value !== undefined && (
					<pre>{JSON.stringify(value, null, 2)}</pre>
				)}

				{f.name && value === undefined && f.emptyText}
			</span>
		);
	};

	const paginate = (arr: any[], size: number) => {
		return arr.reduce((acc, val, i) => {
			const idx = Math.floor(i / size);
			const page = acc[idx] || (acc[idx] = []);
			page.push(val);

			return acc;
		}, []);
	};

	const page = paginate(items, perPage);

	const currentItemsPage = nosortable ? filteredData : page[currentPage - 1];

	return (
		<>
			<ConfirmModal
				title='Atención'
				text={
					selectedElement && selectedElement.status === 'active'
						? 'Desea pasar el elemento a INACTIVO?'
						: 'Desea REACTIVAR el elemento?'
				}
				onAccept={handleSwitchStatusConfirm}
				onClose={handleSwitchStatusClose}
				visible={modalConfirmationVisible}
			/>
			<Card stretch shadow='none'>
				<CardBody className='table-responsive no-padding' isScrollable>
					<div className='h-100'>
						{(showSearch || title) && (
							<div className='d-flex mb-2 pt-1 ps-2 pe-2'>
								<div className='col-md-8 mt-1'>
									<h3>{title}</h3>
								</div>

								{showSearch && (
									<div className='col-md-4 mb-2 d-flex'>
										<Input
											id='search'
											name='search'
											onChange={handleFilterChange}
											placeholder={searchText ?? 'Filtrar'}
											value={filterText}
										/>
										<Button
											size='sm'
											color='dark'
											isLight={darkModeStatus}
											onClick={() => filterTable(filterText)}
											icon='Search'
											className='mt-1 ms-2'
										/>
									</div>
								)}
							</div>
						)}
						<table className='table table-modern table-hover'>
							<thead>
								<tr>
									{fields?.map((f, index) => (
										// eslint-disable-next-line react/no-array-index-key
										<th
											key={index}
											onClick={() => requestSort(f.name)}
											className={classNames(
												f.className,
												f.shrink ? 'fit' : '',
												f.sortable
													? 'cursor-pointer text-decoration-underline'
													: '',
												f.className,
											)}>
											{/* {f.icon && (
											<Icon className='navigation-icon' icon={f.icon} />
										)} */}
											{f.title && <span>{f.title}</span>}
										</th>
									))}
									<th>&nbsp;</th>
								</tr>
							</thead>
							<tbody>
								{loading && (
									<tr>
										<td colSpan={(fields?.length ?? 0) + 1}>
											<Loading visible={loading} />
										</td>
									</tr>
								)}
								{!loading &&
									currentItemsPage?.map((item: any, i: number) => (
										<tr key={item.id}>
											{fields?.map((f) => {
												const commentsValue =
													f.commentsName && _.get(item, f.commentsName);

												const value = f.name && _.get(item, f.name);
												const subValue =
													f.subName && _.get(item, f.subName);

												return (
													<td
														key={f.name}
														className={classNames(
															f.icon ? 'align-top' : '',
															f.shrink ? 'fit' : '',
															f.className,
														)}
														onClick={
															f.selectable
																? () => handleSelect(item)
																: undefined
														}>
														{f.isButton &&
															f.visible &&
															f.visible(item, f, i) && (
																<Button
																	isOutline={!darkModeStatus}
																	color={f.color ?? 'dark'}
																	size='sm'
																	isLight={darkModeStatus}
																	isDisable={
																		f.disabled &&
																		f.disabled(item, f)
																	}
																	className={classNames(
																		f.className,
																		'text-nowrap',
																		'ms-2',
																		{
																			'': !darkModeStatus,
																		},
																	)}
																	onClick={() =>
																		f.click && f.click(item, f)
																	}
																	icon={f.icon}>
																	{fieldDisplay(item, f)}
																</Button>
															)}
														{f.isButton !== true && (
															<>
																{f.icon && (
																	<Icon
																		className='navigation-icon'
																		icon={f.icon}
																	/>
																)}
																{f.imageName && (
																	<img src={item[f.imageName]} />
																)}

																{f.name &&
																	(editionMode // eslint-disable-next-line react-hooks/rules-of-hooks
																		? editionField(
																				item,
																				f,
																				(v: any) => {
																					item[
																						f.name ?? ''
																					] = v;
																				},
																		  )
																		: fieldDisplay(item, f))}
																{f.subName && item[f.subName] && (
																	<div>
																		<legend>
																			<LinesEllipsis
																				text={subValue}
																				maxLine='2'
																				ellipsis='...'
																				trimRight
																				basedOn='words'
																			/>
																		</legend>
																	</div>
																)}
																{commentsValue && (
																	<div>
																		<legend>
																			<LinesEllipsis
																				text={commentsValue}
																				maxLine='2'
																				ellipsis='...'
																				trimRight
																				basedOn='words'
																			/>
																		</legend>
																	</div>
																)}
																{f.tagsName && item[f.tagsName] && (
																	<div className='mt-1'>
																		{item[f.tagsName].map(
																			(
																				l: string,
																				index: number,
																			) => (
																				<Badge
																					key={index}
																					color='primary'
																					className='me-2'>
																					{l}
																				</Badge>
																			),
																		)}
																	</div>
																)}
																{f.footerRender && f.footerRender(item, f)}
															</>
														)}
													</td>
												);
											})}

											<td className='fit'>
												{onSelect && (
													<Button
														isOutline={!darkModeStatus}
														color='dark'
														isLight={darkModeStatus}
														className={classNames(
															'text-nowrap',
															'ms-2',
															{
																'border-light': !darkModeStatus,
															},
														)}
														onClick={() => onSelect(item)}
														icon={onSelectIcon ?? 'Visibility'}
													/>
												)}
												{onEdit && (
													<Button
														color='dark'
														isLight={darkModeStatus}
														className={classNames(
															'text-nowrap',
															'ms-1',
															{
																'border-light': !darkModeStatus,
															},
														)}
														onClick={() => onEdit(item)}
														icon='Edit'
														size='sm'
													/>
												)}
												{onSwitchStatus && (
													<Button
														isOutline={!darkModeStatus}
														color='dark'
														size='sm'
														isLight={darkModeStatus}
														className={classNames(
															'text-nowrap',
															'ms-2',
															{
																'border-light': !darkModeStatus,
															},
														)}
														onClick={() => handleSwitchStatus(item)}
														icon={
															item.status && item.status === 'ACTIVE'
																? 'ToggleOn'
																: 'ToggleOff'
														}
													/>
												)}
												{onDelete && (
													<Button
														isOutline={!darkModeStatus}
														color='dark'
														size='sm'
														isLight={darkModeStatus}
														className={classNames(
															'text-nowrap',
															'ms-2',
															{
																'border-light': !darkModeStatus,
															},
														)}
														onClick={() => handleDelete(item)}
														icon='Delete'
													/>
												)}
											</td>
										</tr>
									))}
								<tr>
									<td colSpan={(fields?.length ?? 0) + 1}>
										{(!data || data.length === 0) && !loading && (
											<div className='m-20 text-center'>
												{emptyMessage ?? 'No existen registros.'}
											</div>
										)}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</CardBody>
				{!pagingHidden && (
					<PaginationButtons
						data={filteredData as unknown[]}
						label='registros'
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
						perPage={perPage}
						setPerPage={setPerPage}
					/>
				)}
			</Card>
		</>
	);
};

export default EntityTable;
