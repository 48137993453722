import React, { FC, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from "@this/template/components/bootstrap/OffCanvas"
import Spinner from "@this/template/components/bootstrap/Spinner"
import loading from "@this/template/components/loading"
import api from '@this/impl/api';
import Button from "../../components/button"

interface CanvasProps {
    visible: boolean;
    onClose: () => void;
    children: ReactNode;
    title?: string;
}

const Canvas: FC<CanvasProps> = (props) => {
    const { visible, onClose, children, title } = props;
    
    return (<OffCanvas setOpen={onClose} isOpen={visible} titleId='canvas' isBodyScroll placement='end'>
        <OffCanvasHeader setOpen={onClose} className='border-bottom'>
            <OffCanvasTitle id='canvas'>{title}</OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody className='d-flex flex-column h-90'>
            <div className='flex-grow-1'>{children}</div>
        </OffCanvasBody>
    </OffCanvas>
    )
}

export default Canvas;