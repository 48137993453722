export const membershipMenu = {
	id: 'memberhsip',
	text: 'Usuarios y Permisos',
	icon: 'Groups',
	path: '/admin/membership',
	subMenu: {
		users: {
			id: 'users',
			text: 'Usuarios',
			path: '/admin/membership/users',
			icon: 'Person',
			subMenu: null,
			privileges: ['users.read'],
		},
		profiles: {
			id: 'profiles',
			text: 'Perfiles',
			path: '/admin/membership/profiles',
			icon: 'Group',
			subMenu: null,
			privileges: ['profiles.read'],
		},
	},
};

export const contentsMenu = {
	id: 'contents',
	text: 'Contenidos',
	icon: 'Dashboard',
	path: '/admin/contents',
	subMenu: {
		documents: {
			id: 'documents',
			text: 'Documentos',
			path: '/admin/contents/documents',
			icon: 'Dashboard',
			privileges: ['documents.read'],
		},
		structures: {
			id: 'structures',
			text: 'Estructuras',
			path: '/admin/contents/structures',
			icon: 'Folder',
			subMenu: null,
			privileges: ['structures.read'],
		},
		categories: {
			id: 'categories',
			text: 'Categorias',
			path: '/admin/contents/categories',
			icon: 'Folder',
			subMenu: null,
			privileges: ['categories.read'],
		},
		rules: {
			id: 'rules',
			text: 'Reglas de Publicacion',
			path: '/admin/contents/rules',
			icon: 'Rule',
			subMenu: null,
			privileges: ['rules.read'],
		},
		processRules: {
			id: 'processRules',
			text: 'Reglas de Procesamiento',
			path: '/admin/contents/process/rules',
			icon: 'Rule',
			subMenu: null,
			privileges: ['processRules.read'],
		},
		contentConfigurations: {
			id: 'contentConfigurations',
			text: 'Configuración',
			path: '/admin/contents/configurations',
			icon: 'Settings',
			subMenu: null,
			privileges: ['documents.write'],
		},
		// approbals: {
		// 	id: 'authorize',
		// 	text: 'Autorizar',
		// 	path: '/admin/contents/approvals',
		// 	icon: 'FactCheck',
		// 	subMenu: null,
		// },
	},
};

export const sectionsMenu = {
	id: 'sections',
	text: 'Secciones',
	path: '/admin/sections',
	icon: 'AccountTree',
	privileges: ['sections.read'],
};

export const adminMenu = {
	membership: membershipMenu,
	contents: contentsMenu,
	sections: sectionsMenu,
	analytics: {
		id: 'analytics',
		text: 'Reportes',
		path: '/admin/analytics',
		icon: 'BarChart',
		privileges: ['reports.read'],
	},
};
