import { IEntityQuery } from '@this/impl/api/entityQuery';
import Modal, { ModalHeader, ModalBody } from '@this/template/components/bootstrap/Modal';
import React, { ReactElement, useEffect, useState } from 'react';
import Icon from '@this/template/components/icon/Icon';
import FormGroup from '@this/template/components/bootstrap/forms/FormGroup';
import dayjs from 'dayjs';
import Input from '@this/template/components/bootstrap/forms/Input';
import Button from '@this/template/components/bootstrap/Button';
import useDarkMode from '@this/template/hooks/useDarkMode';
import { useDebounce } from 'react-use';
import Select from '@this/template/components/bootstrap/forms/Select';
import Breadcrumb from '@this/template/components/bootstrap/Breadcrumb';
import { EntityField } from './entity.field';
import EntityTable from './table';

interface EntitySearchProps<T> {
	title?: string;
	fields: EntityField[];
	entityName: string;
	entityQuery: IEntityQuery<T>;
	isTree: boolean;
	visible: boolean;
	onClose?: () => void;
	onSelect?: (entity: T) => void;
	onFilter?: (filter: any) => void;
	onSelectIcon?: string;
}

const EntityModalSearch = ({
	title,
	fields,
	entityName,
	entityQuery,
	isTree,
	visible,
	onClose,
	onSelect,
	onFilter,
	onSelectIcon,
}: EntitySearchProps<any>) => {
	const { darkModeStatus } = useDarkMode();
	const [openStatus, setOpenStatus] = useState<boolean>(false);
	const [query, setQuery] = useState();
	const [node, setNode] = useState<any>();
	const [nodeNavigation, setNodeNavigation] = useState<any[]>([]);

	useEffect(() => {
		setOpenStatus(visible);
	}, [visible]);

	useEffect(() => {
		if (node) {
			const refreshed = entityQuery.tree?.data?.find((n: any) => n.id === node.id);

			if (refreshed) {
				setNode(refreshed);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entityQuery.tree?.data]);

	const handleSelect = (e: any) => {
		if (e) {
			setNodeNavigation([...nodeNavigation, e]);
		}
		setNode(e);

		if (!isTree) {
			if (onSelect) {
				onSelect(e);
				setOpenStatus(false);
			}
		}
	};

	const handleBack = () => {
		nodeNavigation.pop();
		setNodeNavigation(nodeNavigation);
		const parent = nodeNavigation[nodeNavigation.length - 1];
		if (parent) setNode(parent);
		else setNode(null);
	};

	useEffect(() => {
		if (!openStatus && onClose) {
			onClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openStatus]);

	const [data, setData] = useState<any>();

	useEffect(() => {
		if (isTree) {
			setData(node ? node.children : entityQuery.tree?.data);
		} else {
			setData(entityQuery.all.data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entityQuery.tree?.data, entityQuery.all.data, node]);

	const parent = nodeNavigation.length > 0 ? nodeNavigation[nodeNavigation.length - 1] : null;
	const loading = entityQuery.all.isFetching;

	const [filter, setFilter] = useState();

	const [, cancel] = useDebounce(
		() => {
			setQuery(filter);
			if (onFilter) {
				onFilter(filter);
			}
		},
		500,
		[filter],
	);

	const handleFilterChange = (e: any) => setFilter(e.target.value);

	return (
		<Modal
			setIsOpen={setOpenStatus}
			isOpen={openStatus}
			isStaticBackdrop
			isScrollable
			data-tour='search-modal'
			size='lg'>
			<ModalHeader setIsOpen={setOpenStatus} className='border-bottom'>
				<Icon icon='Search' size='2x' color='primary' />
				<div className='fs-5 ms-2'>{title}</div>
			</ModalHeader>
			<ModalBody className='d-flex h-100' style={{ minHeight: 700 }}>
				<div className='h-auto d-inline-block w-100'>
					<div className='d-flex flex-column p-3 h-90'>
						<div className='align-self-stretch flex-grow-1'>
							<div className='d-flex flex-column'>
								<Breadcrumb
									isToHome={false}
									list={node?.slug?.split('/').map((s: any) => {
										return {
											title: s,
										};
									})}
								/>
								{isTree && nodeNavigation && nodeNavigation?.length > 0 && (
									<div className='mt-2 mb-2 ms-3 border-top pt-2'>
										<Button
											color={darkModeStatus ? 'light' : 'dark'}
											onClick={handleBack}
											isLight
											icon='ArrowBack'
											className='rounded-1'
											size='sm'>
											Regresar
										</Button>
									</div>
								)}
							</div>

							<EntityTable
								searchText='Buscar'
								showSearch
								data={data}
								fields={fields}
								onSelect={handleSelect}
								loading={loading}
								onSelectIcon={onSelectIcon}
							/>

						</div>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default EntityModalSearch;
