import Membership from './membership';
import Contents from './contents';
import Rules from './rules';
import Public from './public';

export default {
	Public,
	Membership,
	Contents,
	Rules,
};
