import React, { ReactNode, useContext, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import CustomSubHeader from '@this/impl/pages/public/aside/subheader';
import CustomMainAside from '@this/impl/pages/public/aside/main';
import Brand from '@this/template/layout/Brand/Brand';
import Navigation, { NavigationLine } from '@this/template/layout/Navigation/Navigation';
import User from '@this/template/layout/User/User';
import {
	dashboardPagesMenu,
	demoPagesMenu,
	pageLayoutTypesPagesMenu,
	summaryPageTopMenu,
} from '@this/template/menu';
import ThemeContext from '@this/template/contexts/themeContext';
import Icon from '@this/template/components/icon/Icon';
import Aside, {
	AsideBody,
	AsideFoot,
	AsideHead,
	AsideSubHeader,
} from '@this/template/layout/Aside/Aside';
import Popovers from '@this/template/components/bootstrap/Popovers';
import { adminMenu, contentsMenu, membershipMenu } from '@this/impl/menus/admin';
import axios from 'axios';

const AdminAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<Aside>
			<AsideHead className='text-center'>
				{asideStatus && (
					<img style={{ width: '150px' }} src='/images/fw.png' alt='Logo' />
				)}
			</AsideHead>
			<AsideBody >
				<div className='p-2'>
					<Navigation id='admin' menu={adminMenu} />
				</div>
			</AsideBody>
			<AsideFoot>
				<User collapsible asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideFoot>
		</Aside>
	);
};

export default AdminAside;
