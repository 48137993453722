import api from '@this/impl/api';
import Accordion, { AccordionItem } from '@this/template/components/bootstrap/Accordion';
import AuthContext from '@this/template/contexts/authContext';
import { NavigationLine } from '@this/template/layout/Navigation/Navigation';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Canvas from '../documents/canvas';
import DocumentsCards from '../documents/cards';
import Sections from './sections.navigation';

const Main = ({ }) => {
	const { isLoggedIn } = useContext(AuthContext);

	const logged = isLoggedIn();

	return (
		<>
			<NavigationLine />
			{logged && <Sections layout="A" />}
			<NavigationLine />
			{logged && <Sections layout="B" />}
		</>
	);
};

export default Main;
