import React, { useEffect, useState } from 'react';
import Button from '@this/template/components/bootstrap/Button';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '@this/template/components/bootstrap/Modal';
import Icon from '@this/template/components/icon/Icon';
import useDarkMode from '@this/template/hooks/useDarkMode';

interface ConfirmModalProps<T> {
	title?: string;
	text?: string;
	visible: boolean;
	onClose?: () => void;
	onAccept?: (entity: T) => void;
}

const ConfirmModal = ({ title, text, visible, onClose, onAccept }: ConfirmModalProps<any>) => {
	const { darkModeStatus } = useDarkMode();
	const [openStatus, setOpenStatus] = useState<boolean>(false);
	const [query, setQuery] = useState();

	useEffect(() => {
		setOpenStatus(visible);
	}, [visible]);

	useEffect(() => {
		if (!openStatus && onClose) {
			onClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openStatus]);

	return (
		<Modal
			setIsOpen={setOpenStatus}
			isOpen={openStatus}
			isStaticBackdrop
			isScrollable
			data-tour='confirm-modal'>
			<ModalHeader setIsOpen={setOpenStatus} className='border-bottom'>
				<label className='border-0 bg-transparent' htmlFor='searchInput'>
					<Icon icon='VerifiedUser' size='2x' color='primary' />
				</label>
				<div className='ms-2'>{title}</div>
			</ModalHeader>
			<ModalBody className='h-50'>{text}</ModalBody>
			<ModalFooter className='border-top mt-2'>
				<Button
					onClick={onAccept}
					color={darkModeStatus ? 'light' : 'warning'}
					className='rounded-1'
					size='sm'>
					Aceptar
				</Button>
				<Button
					onClick={onClose}
					color={darkModeStatus ? 'light' : 'secondary'}
					className='rounded-1'
					size='sm'>
					Cancelar
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ConfirmModal;
