import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import jwt from 'jwt-decode';
import axios from 'axios';
import _, { unescape } from 'lodash';
import Profile from '@this/impl/api/models/profile';
import { useLocation } from 'react-router-dom';
import { getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';

export interface IAuthContextProps {
	token: string | undefined;
	userData: Partial<IUserProps>;
	logout(): any;
	hasPrivilege(privileges: string[]): boolean | undefined;
	isLoggedIn(): boolean;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}

export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const params = new URLSearchParams(window.location.search);
	const jwtToken = params.get('jwt');

	const [token, setToken] = useState<string | undefined>(
		jwtToken || localStorage.getItem('access_token') || '',
	);

	const logout = () => {
		localStorage.removeItem('access_token');
		setToken(undefined); // this is been used as logout. should be replaced with a proper logout function
		window.location.href = '/login';
	};

	const isLoggedIn = (): boolean => {
		return token !== undefined && token !== '';
	};

	const hasPrivilege = (privileges: string[]) => {
		return userData?.privileges?.some((p) => privileges.includes(p)) || userData?.sudo === true;
	};

	const [userData, setUserData] = useState<Partial<IUserProps>>({});

	useEffect(() => {
		if (token) {
			localStorage.setItem('access_token', token);
		}
	}, [token]);

	useEffect(() => {
		if (token) {
			axios.defaults.headers.common.Authorization = `Bearer ${token}`;
			const payload: any = jwt(token);

			const profilesPrivileges = _.flatMapDeep(
				payload.user?.profiles?.map((p: Profile) => p.privileges),
			);
			setUserData({
				id: payload.id ?? '',
				email: payload.email,
				username: payload.sub ?? '',
				name: payload.name,
				privileges: [...(payload.user?.privileges ?? []), ...profilesPrivileges],
				sudo: payload.user?.sudo ?? false,
			});
		} else {
			axios.defaults.headers.common.Authorization = '';
			setUserData({});
		}
	}, [token]);

	const value = useMemo(
		() => ({
			logout,
			isLoggedIn,
			token,
			userData,
			hasPrivilege,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[token, userData],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
