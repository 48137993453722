const config = {
	title: process.env.REACT_APP_SITE_NAME,
	version: process.env.REACT_APP_VERSION,
	apiHost:
		process.env.NODE_ENV === 'production'
			? window.env.REACT_APP_API_HOST
			: process.env.REACT_APP_API_HOST,
};

export default config;
