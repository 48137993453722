import React, { lazy } from 'react';

const ADMIN = {
	DASHBOARD: lazy(() => import('@this/impl/pages/admin/welcome')),
	CONTENTS_CREATE: lazy(
		() => import('@this/impl/pages/admin/contents/documents/documents.edition'),
	),
	CONTENTS: {
		DOCUMENTS: lazy(() => import('@this/impl/pages/admin/contents/documents/documents')),
		DOCUMENTS_EDITION: lazy(
			() => import('@this/impl/pages/admin/contents/documents/documents.edition'),
		),
		CATEGORIES: lazy(() => import('@this/impl/pages/admin/contents/categories')),
		STRUCTURES: lazy(() => import('@this/impl/pages/admin/contents/structures')),
		RULES: lazy(() => import('@this/impl/pages/admin/contents/rules.definitions')),
		PROCESSRULES: lazy(() => import('@this/impl/pages/admin/contents/processRules')),
		CONFIGURATIONS: lazy(() => import('@this/impl/pages/admin/contents/configuration')),
	},
	SECTIONS: lazy(() => import('@this/impl/pages/admin/contents/sections')),
	MEMBERSHIP: {
		USERS: lazy(() => import('@this/impl/pages/admin/membership/users')),
		PROFILES: lazy(() => import('@this/impl/pages/admin/membership/profiles')),
	},
	ANALYTICS: lazy(() => import('@this/impl/pages/admin/analytics')),
};

const routes = [
	{
		path: '/admin',
		element: <ADMIN.DASHBOARD />,
	},
	{
		path: '/admin/analytics',
		element: <ADMIN.ANALYTICS />,
	},
	{
		path: '/admin/contents/structures',
		element: <ADMIN.CONTENTS.STRUCTURES />,
	},
	{
		path: '/admin/contents/categories',
		element: <ADMIN.CONTENTS.CATEGORIES />,
	},
	{
		path: '/admin/contents/process/rules',
		element: <ADMIN.CONTENTS.PROCESSRULES />,
	},
	{
		path: '/admin/contents/configurations',
		element: <ADMIN.CONTENTS.CONFIGURATIONS />,
	},
	{
		path: '/admin/sections',
		element: <ADMIN.SECTIONS />,
	},
	{
		path: '/admin/contents/documents/create',
		element: <ADMIN.CONTENTS.DOCUMENTS_EDITION />,
	},
	{
		path: '/admin/contents/rules',
		element: <ADMIN.CONTENTS.RULES />,
	},
	{
		path: '/admin/contents/documents/:id',
		element: <ADMIN.CONTENTS.DOCUMENTS_EDITION />,
	},
	{
		path: '/admin/contents/documents',
		element: <ADMIN.CONTENTS.DOCUMENTS />,
	},
	{
		path: '/admin/membership/profiles',
		element: <ADMIN.MEMBERSHIP.PROFILES />,
	},
	{
		path: '/admin/membership/users',
		element: <ADMIN.MEMBERSHIP.USERS />,
	},
];

export default routes;
