import React, { lazy } from 'react';
import AdminRoutes from './admin/content.routes';

const DOCUMENTS = {
	DOCUMENT_VIEW: lazy(() => import('../pages/public/documents/view')),
	DOCUMENT_LIST: lazy(() => import('../pages/public/documents/list')),
	DOCUMENT_SEARCH_RESULTS: lazy(() => import('../pages/public/documents/search.results')),
	DOCUMENT_HISTORY_LIST: lazy(() => import('../pages/public/documents/history')),
};

const STRUCTURES = {
	STRUCTURE_VIEW: lazy(() => import('../pages/public/structures/view')),
};

const SECTIONS = {
	SECTION_VIEW: lazy(() => import('../pages/public/sections/view')),
};

const LOGIN = lazy(() => import('../pages/login/Login'));

const presentation = [
	{
		path: '/login',
		element: <LOGIN />,
	},
	{
		path: '/',
		element: <DOCUMENTS.DOCUMENT_HISTORY_LIST />,
	},
	{
		path: '/documents',
		element: <DOCUMENTS.DOCUMENT_LIST />,
	},
	{
		path: '/documents/search',
		element: <DOCUMENTS.DOCUMENT_SEARCH_RESULTS />,
	},
	{
		path: '/documents/:id',
		element: <DOCUMENTS.DOCUMENT_VIEW />,
	},
	{
		path: '/structures/:id',
		element: <STRUCTURES.STRUCTURE_VIEW />,
	},
	{
		path: '/sections/:id',
		element: <SECTIONS.SECTION_VIEW />,
	},
];

const contents = [...presentation, ...AdminRoutes];

export default contents;
